@font-face {
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  src: local('Share Tech'), local('ShareTech-Regular'), url(/fonts/ShareTech-Regular.woff2) format('woff2'), url(/fonts/ShareTech-Regular.ttf) format('truetype');
}

body {
  background:#222 url('/img/logowall_opt.svg') center top;
  background-size: 300px;
  color: white;
}

h1 {
  padding-left:160px;
  padding-bottom:20px;
  color: $nav-link-color;
}

a, .navbar-default .navbar-nav li a, .navbar-nav > li > a {
  color: red;
  text-shadow: none;

  &:hover, &:visited {
    color: $link-hover;
    text-decoration: none;
  }
}

.content {
  padding-top:90px;
  padding-left: 0;
  padding-right: 0;
  background:$primary-bg url('/img/logowall_opt.svg') center top;
  background-size: 300px;
}

.header {
  background-color: $primary-bg;
  }
.logo {
    position:relative;
    z-index:10;
    width:150px;
    margin-bottom: -40px;
  }

.navigation {
  height:50px;
  background-color: $primary-bg;
  border-bottom: 1px #aaa solid;
}

.navbar-default {
  background:none;
  box-shadow:none;
  border:0;

  .in, .collapsing {
    margin-top:-60px;
    .navbar-nav {
      background-color: $primary-bg;
      li {
        text-align:right;
      }
    }
  }
}

.post, .box {
  background-color: #444;
  padding: 40px 20px;
  margin-bottom: 30px;
  border-radius: 5px;

  .date {
    font-style: italic;
    font-size: 12px;
  }

  a {
    color: $link-primary;

    &:hover {
        color: $link-hover;
      }
  }

  h2 {
    a {
      color: white;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.singlepost {
  margin-top:60px;
}

.footer {
  padding: 20px;
  ul {
    list-style-type: none;
  }
}

#footer-nav-links a, #header-nav-links a {
  color:$nav-link-color;

  &:visited {
    color:$nav-link-color;
  }

  &:hover {
    color:$nav-link-hover;
  }
}

// Caption for images
img + em {
  font-size: 1.25rem;
}